import * as React from 'react';
import Button from '@mui/material/Button';
import { Link,useLocation, useNavigate } from 'react-router-dom';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider,Box } from '@mui/material';
import CustomModal from '../../Components/Modal';
import LoginPage from '../../Pages/Login';
import RegisterPage from '../../Pages/Register';
import { cutName } from '../../Functions';


export default function MenuUser(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const modalRef = React.useRef();
  const handleOpenModal = (content) => {
    modalRef.current.openModal(content);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const handleLogout = () => {
    // Cerrar la sesión
    localStorage.removeItem('token');
    window.location.reload(); // Recargar la página actual
    window.location.href = '/'; // Cambia '/'' al URL de tu página de inicio

  };


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          sx={{ borderRadius: '50px'}}
          color="primary"
          id="composition-button"
          size="medium"
          variant="outlined" 
          startIcon={!props.userName ? <MenuIcon/> : null}
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={props.auth ? handleToggle : ()=>handleOpenModal(<LoginPage/>)}
        >
          <Box sx={{textTransform:'capitalize', mr:.2}}>
          {cutName(props.userName)}
          </Box>    

          <AccountCircleIcon/>
        </Button>


        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >

              <Paper           
              sx={{minWidth:'260px', borderRadius:'15px'}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList 
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
  
                    {
                    props.auth ? 
                    <>
                    <MenuItem 
                      onClick={()=>setOpen(false)}
                      component={Link}
                      to={`/panel/my-messages`}>
                      Mensajes
                    </MenuItem>


                    <MenuItem 
                      onClick={()=>setOpen(false)}
                      component={Link}
                      to={`/panel/my-posts`}>
                      Mis publicaciones
                    </MenuItem>




                    <MenuItem 
                      onClick={()=>setOpen(false)}
                      component={Link}
                      to={`/configuration`}>
                      Configuracion
                      </MenuItem>
                      <Divider/>
                      <MenuItem onClick={handleLogout}>Cerrar sesion</MenuItem>
                    </>
                      : 
                      <>

                    <MenuItem onClick={()=>handleOpenModal(<LoginPage/>)}>Iniciar sesión</MenuItem>

                    </>

                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
    

<CustomModal ref={modalRef} buttonText="Open Modal" modalTitle="Iniciá sesión o registrate" />

</>
  );
}