import React, { useState } from 'react';
import {
  Button,
  TextField,
  Stack,
  Box
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ContactForm = () => {
  const [message, setMessage] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('email');
  const [selectedMethod, setSelectedMethod] = useState('email');

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Aquí puedes enviar el mensaje según el método elegido (email o WhatsApp)
    if (selectedMethod === 'email') {
      // Lógica para enviar por correo electrónico
      // Por ejemplo, puedes abrir un cliente de correo electrónico o enviar una solicitud al servidor
    } else if (selectedMethod === 'whatsapp') {
      // Lógica para enviar por WhatsApp
      // Por ejemplo, puedes abrir la aplicación de WhatsApp o generar un enlace de WhatsApp
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Mensaje"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />


      <Box display="flex" flexDirection="row" marginTop={2} sx={{ flex: 1 }}>
        <Button
          variant={selectedMethod === 'email' ? 'outlined' : 'text'}
          startIcon={<EmailIcon />}
          onClick={() => handleMethodChange('email')}
          sx={{ flex: 1, borderRadius: 0 }}

        >
          Correo Electrónico
        </Button>
        <Button
          variant={selectedMethod === 'whatsapp' ? 'outlined' : 'text'}
          startIcon={<WhatsAppIcon />}
          onClick={() => handleMethodChange('whatsapp')}
          sx={{ flex: 1, borderRadius: 0 }}
        >
          WhatsApp
        </Button>
      </Box>

      <Button type="submit" variant="contained" size="large" fullWidth sx={{ mt: 10 }}>
        Enviar
      </Button>
    </form>
  );
};

export default ContactForm;

