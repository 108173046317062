import React, { useEffect } from 'react';
import Foot from '../../Theme/Foot';
import { Container, Grid, Typography, TextField, Button, Box } from '@mui/material';

const Contact = () => {
  const pageName = 'Politicas de privacidad';
  useEffect(() => {document.title = pageName;}, []);



  const sections = [
    { label: 'Home', link: '/' },
    { label: pageName, link: './' },
  ];


  return (<>

<Box className='container-page' sx={{mb:8}}>
    <h2>Política de Privacidad de Brilancer</h2>

    <h3>1. Introducción</h3>

    <p>Bienvenido a Brilancer. En Brilancer, valoramos y respetamos tu privacidad. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos la información que recopilamos de los usuarios de nuestro sitio web y servicios. Al utilizar nuestro sitio web y servicios, aceptas las prácticas descritas en esta política.</p>

    <h3>2. Información que Recopilamos</h3>

    <p>Recopilamos información que nos proporcionas directamente cuando te registras en Brilancer, completas tu perfil, participas en discusiones, publicas contenido o te comunicas con nosotros. La información que recopilamos puede incluir tu nombre, dirección de correo electrónico, información de contacto y cualquier otra información que elijas proporcionar.</p>


    <h3>3. Uso de la Información</h3>

    <p>Utilizamos la información recopilada para:</p>

    <ul>
        <li>Proporcionar y mantener nuestro servicio.</li>
        <li>Personalizar tu experiencia y ofrecer contenido y funciones adaptados a tus intereses.</li>
        <li>Mejorar nuestro sitio web y servicios.</li>
    </ul>

    <h3>4. Compartir Información</h3>

    <p>No compartimos tu información personal con terceros, excepto en casos previstos por la ley o cuando tengamos tu consentimiento.</p>


    <h3>5. Cookies y Tecnologías Similares</h3>

    <p>Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario. Puedes gestionar las preferencias de cookies desde la configuración de tu navegador.</p>


    <h3>6. Seguridad</h3>

    <p>Implementamos medidas de seguridad para proteger tu información. Sin embargo, ninguna transmisión por Internet o almacenamiento electrónico es completamente segura, y no podemos garantizar la seguridad absoluta de la información.</p>


    <h3>7. Cambios en la Política de Privacidad</h3>

    <p>Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Te notificaremos sobre cambios significativos mediante una notificación en nuestro sitio web o por otros medios.</p>

    <h3>8. Contacto</h3>

    <p>Si tienes preguntas sobre esta Política de Privacidad, contáctanos en <a href="mailto:hello@brilancer.com">hello@brilancer.com</a>.</p>

    <p>Gracias por confiar en Brilancer.</p>
  
    

    </Box>
    <Foot/>
  </>
  );
};

export default Contact;
