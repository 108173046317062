import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home'; // Icono de casa
import PostAddIcon from '@mui/icons-material/PostAdd'; // Icono de publicar
import StarIcon from '@mui/icons-material/Star'; // Icono de favoritos
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para la navegación
import CustomModal from '../Components/Modal';
import LoginPage from '../Pages/Login';

export default function BottomNav(props) {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate(); // Utiliza useNavigate para la navegación
  const modalRef = React.useRef();
  const handleOpenModal = (content) => {
    modalRef.current.openModal(content);
  };

  const handleNavigation = (newValue, route) => {
    navigate(route);
    setValue(newValue);
  };

  return (<>
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: { xs: 'block', sm: 'none' },
        zIndex: 999999,
      }}
      elevation={1}
    >
      <BottomNavigation
        sx={{ backgroundColor: '#fff' }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Inicio"
          icon={<HomeIcon />}
          sx={{ margin: '0 8px' }}
          onClick={() => handleNavigation(0, '/')}
        />
        <BottomNavigationAction
          label="Publicar"
          icon={<PostAddIcon />}
          sx={{ margin: '0 8px' }}
          onClick={props.auth ? () => handleNavigation(1, '/post/new') : ()=>handleOpenModal(<LoginPage/>)} to={props.auth ? '/post/new' : null}
        />
        <BottomNavigationAction
          label="Favoritos"
          icon={<StarIcon />}
          sx={{ margin: '0 8px' }}
          onClick={props.auth ? () => handleNavigation(1, '/favoritos') : ()=>handleOpenModal(<LoginPage/>)} to={props.auth ? '/post/new' : null}

        />
      </BottomNavigation>
    </Paper>
    <CustomModal ref={modalRef} buttonText="Open Modal" modalTitle="Iniciá sesión o registrate" />
</>


  );
}
