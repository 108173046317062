import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import handleApiRequest from '../ApiUtils';
import Spinner from '../Components/Spinner';
import { Box } from '@mui/material';
import Register from './Register';
import logo from '../Assets/Images/logo.png';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';



const LoginPage = () => {

  const [err, setErr] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoginOrRegister, setIsLoginOrRegister] = React.useState('login');
  const [loginEmail, setLoginEmail] = useState(false);

  const [loggedIn, setloggedIn] = useState(false);
  const [userInfo, setuserInfo] = useState([]);

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    try {
      const response = await handleApiRequest('post', 'login', {
        login: true,
        email: email,
        password: password
      });
      if (response.status == 'success') {
        localStorage.setItem('token', response.data.user_id);
        localStorage.setItem('u_name', response.data.user_name);

        setTimeout(() => {
          window.location.reload();
        }, 500); // espera 500 milisegundos (ajusta según sea necesario)
      } else {
        setIsLoading(false);
        setErr(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      alert(error); // Agrega esta línea para imprimir detalles del error en la consola del navegador
      setErr('Error. No se puede conectar con el servidor.');

    }
  };


  if (isLoading) return <Spinner loading={true} />;


  return (
    <Container >

<>
<Box display="flex" flexDirection="row" marginTop={0} sx={{ flex: 1, mb:4 }}>
        <Button
          variant={isLoginOrRegister == 'login'  ? 'text' : 'text'}
          onClick={() => setIsLoginOrRegister('login')}
          sx={{ flex: 1, borderRadius: 0, border:0, borderBottomWidth:isLoginOrRegister == 'login' ? 1 : 0 }}
          color={isLoginOrRegister == 'login' ? "error" : "inherit"}
        >
          Iniciar sesion
        </Button>
        <Button
          variant={isLoginOrRegister == 'register' ? 'text' : 'text'}
          onClick={() => setIsLoginOrRegister('register')}
          sx={{ flex: 1, borderRadius: 0, border:0, borderBottomWidth:isLoginOrRegister == 'register' ? 1 : 0 }}
          color={isLoginOrRegister == 'register' ? "error" : "inherit"}
        >
          Registrarme
        </Button>
      </Box>

        </>



      {isLoginOrRegister == 'login' ?
        <>
{//          <img src={logo} alt="Logo" className='logo' style={{ width: '140px' }} />
}

            <>
              <TextField
                label="Correo electronico"
                fullWidth
                margin="normal"
                required
                onChange={(e) => {setErr(''); setEmail(e.target.value)}}
              />
              <TextField
                type='password'
                label="Contraseña"
                fullWidth
                margin="normal"
                required
                onChange={(e) => {setErr(''); setPassword(e.target.value)}}

              />

              {err && (
                <Box sx={{ padding: 1, bgcolor: '#FF94A1', color: 'white', borderRadius: 0 }}>
                  {err}
                </Box>
              )}

              <Button onClick={(e) => handleSubmit(e)}
                sx={{ marginTop: 5, mb: 4 }}
                size='large' variant="contained" color="error" fullWidth>
                Ingresar
              </Button>
            </>




{/*           <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2px',
            marginTop: 30,
            marginBottom: 50
          }}>
            <hr style={{ flex: '1', margin: '0 10px 5px' }} />
            <Typography variant="body2" align="center" gutterBottom >
             <b>0</b> 
            </Typography>
            <hr style={{ flex: '1', margin: '0 10px 5px' }} />
          </div>
 */}
{/*  
          <GoogleOAuthProvider clientId="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">
            <GoogleLogin
            size="large"
            shape="square"
           // ux_mode="redirect"
              onSuccess={credentialResponse => {
                alert(JSON.stringify(credentialResponse));
              }}
              onError={() => {
                alert('Login Failed');
              }}
            />;
          </GoogleOAuthProvider> */}


        </>

        :           <Register />

      }






    </Container>
  );
};

export default LoginPage;
