import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom'; // Importa useParams desde react-router-dom
import { Chip, Box, Button, Paper } from '@mui/material';
import Foot from '../Theme/Foot';
import handleApiRequest from '../ApiUtils';
import FavButton from '../Components/FavButton';
import Spinner from '../Components/Spinner';
import TimeAgo from '../Components/TimeAgo';
import LoginPage from './Login';
import ContactarPage from '../Pages/Contactar';
import { extractIdFromUrl } from '../Functions';
import Grid from '@mui/material/Grid';

import CustomModal from '../Components/Modal';
import im1 from '../Assets/Images/prueba.png';

const Info = (props) => {
  const { url } = useParams(); // Usa useParams para obtener el parámetro "id"
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState(null);
  const [id, setId] = useState(extractIdFromUrl(url))
  const modalRef = React.useRef();
  const handleOpenModal = (content) => {
    modalRef.current.openModal(content);
  };

  const fetchProjects = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', `details&id=${id}`);
      if (response.status === 'success') {
        setPost(response.data.post);
        document.title = response.data.post.title;
        setIsLoading(false);

      } else {
        console.error('Error al obtener la lista de proyectos.');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };

  useEffect(() => {
    if (id) {
      fetchProjects();
    }
  }, [id]);

  // Función para manejar el evento de selección de texto
  const handleSelect = (e) => {
    e.preventDefault();
  };

  // Función para manejar el evento del botón derecho del ratón
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  if (!post) return <Spinner loading={true} />;
  return (
    <>
      <Box className='container-page' sx={{ mb: 8 }}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={4} sx={{mt:3.5, }}>
              <img
                src={im1}
                alt="Logo"
                style={{ width: '100%', height: 'auto', maxWidth:'500px', objectFit: 'contain'}}
                className='large-screen-image'
              />
          </Grid>

          <Grid item xs={12} sm={8} >
            <h2>{post.title} <FavButton /></h2>
            <small>Publicado por <b>{post.user_name}</b>,  <TimeAgo dateString={post.create_at} /></small>
            <Box>
              <Chip
                label={post.category}
                color={'default'}
                sx={{ mr: 1, mt: 1, mb: 1 }}
                size='small'
              />
            </Box>
            <hr />

            <div className={props.auth ? '' : 'blurred-text'}
              onCopy={handleSelect}
              onCut={handleSelect}
              onDragStart={handleSelect}
              onDrop={handleSelect}
              onContextMenu={handleContextMenu}
            >
              <Box sx={{ py: 3 }}>
                <p>{post.description}</p>
              </Box>
              <Box><b>¿Qué busco para llevar adelante mi idea?</b></Box>
              <p>{post.partner_criteria}</p>
              <Box sx={{ mt: 2 }}><b>¿Qué estaría dispuesto a proponerte para colaborar en este proyecto?</b></Box>
              <p>{post.partner_proposals}</p>
            </div>

            <Button
              onClick={() => handleOpenModal(props.auth ? <ContactarPage /> : <LoginPage />)}
              sx={{ marginTop: 5 }} className='button-width'
              size='large' variant={props.auth ? "contained" : "outlined"} color="primary">
              {props.auth ? 'Contactar' : 'Inicia sesión o registrate'}
            </Button>
            
          </Grid>
        </Grid>
      </Box>
    <CustomModal ref={modalRef} buttonText="Open Modal" modalTitle="Iniciá sesión o registrate" />

      <Foot />
    </>
  );
};

export default Info;
