import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import MessageIcon from '@mui/icons-material/Message';

import TimeAgo from './TimeAgo';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Item = ({ message, date, userName, email, phone }) => {

  function sendWhatsapp(mensaje, phone) {
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(mensaje)}`;
    window.open(url);
  }

  return (
      <Box style={styles.container}>
        <Box sx={{ paddingRight: 1, textAlign: 'center', color: '#999', width: 50 }}>
          {<MessageIcon style={styles.icon} />}
        </Box>
        <div style={styles.content}>
          <b style={styles.title}>{userName}</b>
          <p style={styles.date}><TimeAgo dateString={date} /></p>

          <p style={styles.description}>{message}</p>
      <Box display="flex" flexDirection="row"  sx={{ flex: 1, justifyContent:'flex-end'}}>
        <Button
         size='small'
          variant={'text'}
          startIcon={<EmailIcon />}
          sx={{ borderRadius: 0, minWidth:2, p:0, mr:3, textTransform:'lowercase' }}

        >
        {email}
        </Button>
        <Box sx={{color:'#ccc'}}>|</Box>
        <Button
          size='small'
          variant={'text'}
          startIcon={<WhatsAppIcon />}
          sx={{ borderRadius: 0,  p:0, ml:3 }}
          onClick={() =>sendWhatsapp("Hola "+userName+"! ", phone )}
        >
        {phone}
        </Button>
      </Box>


      <hr/>

        </div>

      </Box>
  );
};

const styles = {
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  container: {
    display: 'flex',
    padding: '5px',
    borderRadius: '0px',
    paddingRight: '10px',
    paddingTop:'10px',
    position: 'relative',
  },
  icon: {
    fontSize: '28px',
    color: '#cccc',
    magin:0

  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '0px',
  },
  description: {
    fontSize: '14px',
    margin: '10px 0',
    marginTop: '2px',
  },
  button: {
    backgroundColor: '#fff',
    color: 'black',
    fontWeight: '200',
  },
  date: {
    fontSize: '12px',
    color: '#888',
    position: 'absolute',
    top: '15px',
    right: '20px',
  },
  category: {
    fontSize: '12px',
    color: '#888',
    maginBottom:'0px',
  },
};

export default Item;
