import React from 'react';
import { Grid, Paper, Typography, Link, Box  } from '@mui/material';

const MyComponent = () => {
  return (
    <Paper className='foot' sx={{display: { xs: 'none', sm: 'block',zIndex:2 } }}>   
  
    <Box className='container' >
    <Grid container spacing={2} >
      <Grid item xs={12} sm={6} md={3}>
        <b>Buscar</b>
        <ul sx={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
          <li sx={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
            <a href="#">Socio</a>
            <a href="#">Inversionista</a>
            <a href="#">Freelancer</a>

          </li>
        </ul>
        <Box  sx={{  display: { xs: 'block', md: 'none' } }}>
        <hr />
        </Box>
      </Grid> 


      <Grid item xs={12} sm={6} md={3}>
      <b>Menu</b>
        <ul sx={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
          <li sx={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
            <a href="#">Ver todo</a>
            <a href="#">Buscar</a>
          </li>
        </ul>
        <Box  sx={{  display: { xs: 'block', md: 'none' } }}>
        <hr />
        </Box>        
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <b>Ayuda</b>
        <ul sx={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
          <li sx={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
          <a href="#">Centro de ayuda</a>
            <a href="#">Politicas de privacidad</a>
            <a href="#">Condiciones del servicio</a>
            <a href="#">Contacto</a>

          </li>
        </ul>
        <Box  sx={{  display: { xs: 'block', sm: 'none' } }}>
        <hr />
        </Box>        
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
      <b>Social</b>
        <ul sx={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
          <li sx={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
            <a href="#">Facebook</a>
            <a href="#">Instagram</a>
            <a href="#">Twitter</a>
          </li>
        </ul>
      </Grid>

    </Grid>
    </Box>

    <Box className='container'  >
    <hr/>

      <Box sx={{paddingY:2}}>
        <p>© {new Date().getFullYear()} Brilancer. Buenos Aires, Argentina</p>
      </Box>
    </Box>

    </Paper>
  );
};

export default MyComponent;
