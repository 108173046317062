// apiUtils.js

import axios from 'axios';

const apiBaseUrlLocal = 'http://localhost/api_sesocio/index.php';
const apiBaseUrl = 'https://api.brilancer.com/v3/';

//https://api.brilancer.com/v1/

const handleApiRequest = async (method, endpoint, requestData) => {
  try {
    const response = await axios({
      method: method,
      url: `${apiBaseUrl}/?${endpoint}`,
      data: requestData
    });

    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw new Error('Hubo un error en la solicitud.');
  }
};

export default handleApiRequest;