import React, { useState } from 'react';
import { Box} from '@mui/material';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';

const DontFound = ({icon, message}) => {


  return (
  <Box sx={{ textAlign: 'center', mt: 10, color:'#003087' }}>
    <Box > 
    {icon === 'cancel' &&  <CancelPresentationOutlinedIcon sx={{fontSize:'60px', color:'#003087'}} />}
    {icon === 'search' &&  <ScreenSearchDesktopOutlinedIcon sx={{fontSize:'60px', color:'#003087'}} />}

   
    </Box>
    <b>{message}</b>  
  </Box>
  );
};

export default DontFound;
