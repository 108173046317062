import React, { useEffect, useState } from 'react';
import Foot from '../../Theme/Foot';
import Item from '../../Components/Item';
import { Container, Grid, CircularProgress, TextField, Button, Box, Paper } from '@mui/material';
import handleApiRequest from '../../ApiUtils';
import Spinner from '../../Components/Spinner';
import DontFound from '../../Components/DontFound';

const Panel = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const pageName = 'Panel';
  useEffect(() => { document.title = pageName; }, []);







  const getData = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', 'user_posts&user_id='+userId);
      if (response.status === 'success') {
        setPosts(response.data);
        setIsLoading(false);
      } else {
        console.error('Error al obtener la lista de proyectos:');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);





  if (isLoading) return <Spinner loading={true} />;

  return (<>

    <Box className='container-page' mb={5}>
      <h3>Mis publicaciones</h3>
      <hr/>
      {posts.length ? 
      <Box sx={{ mt: 3 }}>
        {posts.map(post => (
          <Item
            title={post.title}
            description={post.description}
            category={post.category}
            date={post.create_at}
            id={post.post_id}
            url='post/edit/'
            horizontalOrVertical="horizontal"

          />
        ))}
      </Box>

      :

      <DontFound icon="cancel" message="No tenes publicaciones."/>

}
    </Box>
    <Foot />
  </>
  );
};

export default Panel;
