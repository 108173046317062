import React, { useEffect } from 'react';
import Foot from '../Theme/Foot';
import Breadcrumb from '../Components/Breadcrumb';
import { Container, Grid, Typography, TextField, Button, Box } from '@mui/material';

const Contact = () => {
  const pageName = 'contact';
  useEffect(() => {document.title = pageName;}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const sections = [
    { label: 'Home', link: '/' },
    { label: pageName, link: './' },
  ];


  return (<>

    <Box className='container-page'>
      <h1>Contactos</h1>
      <Breadcrumb sections={sections} />


      <Grid container spacing={2} sx={{mt:3}}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <TextField label="Name" fullWidth required sx={{mt:2}}/>
            <TextField label="Email" type="email" fullWidth required sx={{mt:2}}/>
            <TextField label="Message" multiline rows={4} fullWidth required sx={{mt:2}}/>
            <Button type="submit" variant="contained" sx={{ mt: 3 }}>
              Send Message
            </Button>
          </form>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Google Maps Embed */}
        
        </Grid>
      </Grid>
    

    </Box>
    <Foot/>
  </>
  );
};

export default Contact;
