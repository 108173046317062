import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Assets/Css/App.css';
import './Assets/Css/index.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
ReactDOM.render(<App />, document.getElementById('root'));
