import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const AddToFavoritesButton = () => {
  const [isFavorite, setIsFavorite] = useState(false);

  const handleAddToFavorites = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <IconButton color="warning" aria-label="Agregar a favoritos" onClick={handleAddToFavorites}>
      {isFavorite ? <StarIcon /> : <StarBorderIcon />}
    </IconButton>



  );
};

export default AddToFavoritesButton;
