


import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../Assets/Images/logo.png';
import MenuUser from './MenuUser';
import CustomModal from '../../Components/Modal';
import LoginPage from '../../Pages/Login';
const drawerWidth = '90%';


function DrawerAppBar(props) {
  const { window, auth } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const modalRef = React.useRef();
  const handleOpenModal = (content) => {
    modalRef.current.openModal(content);
  };

  const navItems = [
    { name: 'Inicio', link: '/' },
    { name: 'Publica tu anuncio!', link: props.auth ? '/post/new' : 'Login' }];



  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
      <Box component="div" >
        <img src={logo} alt="Logo" className='logo' style={{ width: '140px' }} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} to={item.link}  >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const location = useLocation();
  const navigate = useNavigate();

  // Función para ir a la página anterior
  // Función para ir a la página anterior
  // Función para ir a la página anterior
  const handleBack = () => {
    navigate(-1);
  };


  useEffect(() => {
    // Lógica para determinar si estás en la ruta raíz
    const isRoot = location.pathname === '/';

    // Si estás en la ruta raíz, no mostrar el botón de retroceso
    if (isRoot) {
      return;
    }

    // Configurar un event listener para manejar el evento de cambio de ubicación
    const unlisten = () => {
      if (location.pathname === '/') {
        // Aquí puedes realizar alguna acción adicional si es necesario
      }
    };

    // Limpiar el event listener al desmontar el componente
    return () => {
      unlisten();
    };
  }, [location]);


  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />

      <AppBar className='navBar' component="nav" sx={{ boxShadow: 'none', zIndex: 99999 }} >
        <Box className='container-nav'>
          <Toolbar className='toolbar'>
            {location.pathname === '/' ? '' :
              <IconButton
                color="inherit"
                aria-label="go back"
                edge="start"
                onClick={handleBack}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <ArrowBackIcon />
              </IconButton>
            }


            <Box sx={{ mr: 'auto', display: { xs: 'none', sm: 'block' } }}>
              <a href='/' style={{ textDecoration: 'none', color: 'white' }}>
                <img src={logo} alt="Logo" className='logo' style={{ width: '140px' }} />
              </a>
            </Box>

            <Box sx={{ ml: location.pathname === '/' ? 0 : 'auto', pl: location.pathname === '/' ? 0 : 6, mr: 'auto', display: { xs: 'block', sm: 'none' } }}>
              <a href='/' style={{ textDecoration: 'none', color: 'white' }}>
                <img src={logo} alt="Logo" className='logo' style={{ width: '140px' }} />
              </a>
            </Box>

            

            <Box sx={{mr:2, display: { mr: 'auto', xs: 'none', sm: 'block' } }}>


              <Button className='itemNavPub' color="primary" disableRipple variant="contained" key={2} component={Link} onClick={props.auth ? null : () => handleOpenModal(<LoginPage />)} to={props.auth ? '/post/new' : null} >
                Comparte tu Idea!
              </Button>


            </Box>
            <Box sx={{ display: {  xs: 'none', sm: 'block' }, color:'#999' }}>
            |
            </Box>

            <Box sx={{ml:2}}>

            <MenuUser auth={auth} userName={props.userName} />
            </Box>


          </Toolbar>
        </Box>

      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>


      <CustomModal ref={modalRef} buttonText="Open Modal" modalTitle="Iniciá sesión o registrate" />

    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
