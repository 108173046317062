import React, { useState, useEffect } from 'react';
import { formatDistanceToNow, parse } from 'date-fns';
import esLocale from 'date-fns/locale/es'; // Importa el idioma español

function TimeAgo({ dateString }) {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const updateTimeAgo = () => {
      const currentTime = new Date();
      const date = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());

      const timeAgoText = formatDistanceToNow(date, {
        addSuffix: true,
        locale: esLocale, // Establece la localización en español
      });
      setTimeAgo(timeAgoText);
    };

    updateTimeAgo();

    const interval = setInterval(updateTimeAgo, 60000); // Actualiza cada minuto

    return () => clearInterval(interval);
  }, [dateString]);

  return <span>{timeAgo}</span>;
}

export default TimeAgo;
