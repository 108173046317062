// functions.js
export function createFriendlyURL(name) {
  // Eliminar todos los caracteres no deseados y convertir a minúsculas
  const cleanName = name.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase();
  
  // Reemplazar espacios con guiones y convertir a minúsculas
  const friendlyURL = cleanName.replace(/\s+/g, '-');
  
  return friendlyURL;
}

export function extractIdFromUrl(url) {
  // Utilizar expresión regular para encontrar el último dígito al final de la URL
  const match = url.match(/(\d+)$/);

  // Verificar si se encontró una coincidencia
  if (match) {
    // Devolver el último dígito encontrado
    return match[0];
  } else {
    // En caso de que no se encuentre ningún dígito, puedes manejarlo según tus necesidades
    console.error('No se encontró ningún dígito en la URL');
    return null;
  }
}

export function cutName(fullName) {
  if (!fullName) {
    return "";
  }

  const nameParts = fullName.split(" ");
  return nameParts[0];
}


export function truncatedText(text, qty) {
  const truncatedTitle = text.length > qty ? `${text.substring(0, qty)}...` : text;
  return truncatedTitle;
}

