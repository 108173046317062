/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';

const Breadcrumb = ({ sections }) => {
  const handleClick = (event) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };
  const renderLinks = sections.slice(0, -1).map((section, index) => (
    <Link className='bread-item' key={index} underline="hover" color="inherit" href={section.link}>
    {section.label}
    </Link>
  ));
  return (

    <Stack className='breadcrumb' role="presentation" >
      <Breadcrumbs maxItems={3} aria-label="breadcrumb"  >
      {renderLinks}
        <Typography className='bread-item' color="text.primary">{sections[sections.length - 1].label}</Typography>
      </Breadcrumbs>
    </Stack>
  );
};

export default Breadcrumb;