import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import Navbar from './Theme/Navbar/index';
import Footer from './Theme/Footer';
import BottomNav from './Theme/BottomNav';
import Error from './Pages/404';

import Home from './Pages/Home';
import Contact from './Pages/Contact';
import LoginPage from './Pages/Login';
import Search from './Pages/Search';
import Post from './Pages/Post';
import Configuration from './Pages/User/Configuration';
import MyPosts from './Pages/User/MyPosts';
import Messages from './Pages/User/Messages';

import Publicar from './Pages/Publicar';
import PrivacyPolicy from './Pages/Page/PrivacyPolicy';



import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Crea un tema personalizado con el color primario modificado
const theme = createTheme({
  palette: {
    primary: {
      main: '#003087',
    },
    secondary:{main:'#2C3357'},

  },
});

const Root = () => {

  const [authenticated, setAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Verificar la información de inicio de sesión en el almacenamiento local
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('u_name');

    if (token) {
      setUserId(token)
      setUserName(name)
      setAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    // Cerrar la sesión
    localStorage.removeItem('token');
    setAuthenticated(false);
    navigate('/login');
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {authenticated ?
  <>
  
      <Navbar auth={authenticated} handleLogout={handleLogout} userName={userName}/>
      <BottomNav auth={authenticated} userId={userId}/>

      <Box sx={{bgcolor: 'background.default',color: 'text.primary',}} className='App'> 
      <Routes>
        <Route path="/" element={<Home auth={authenticated} />} />
        <Route path="/post/:url" element={<Post auth={authenticated} />} />
        <Route path="/contactos" element={<Contact />} />
        <Route path="/Publicar" element={<Publicar auth={authenticated} userId={userId}/>} />
        <Route path="/configuration" element={<Configuration auth={authenticated} userId={userId}  />} />
        <Route path="/panel/my-messages" element={<Messages auth={authenticated} userId={userId}  />} />
        <Route path="/panel/my-posts" element={<MyPosts auth={authenticated} userId={userId}  />} />
        <Route path="/panel/post/edit/:url" element={<Publicar auth={authenticated} type="edit" userId={userId}/>} />
        <Route path="/post/new" element={<Publicar auth={authenticated} type="new" userId={userId}/>} />
        <Route path="/page/privacy-policy" element={<PrivacyPolicy auth={authenticated} type="new" userId={userId}/>} />

        <Route path="*" element={<Error/>}/>
        <Route path="/search" element={<Search />} />

      </Routes>
      </Box>

:
</>:<>


      <Navbar setAuth={authenticated} handleLogout={handleLogout}/>
      <BottomNav/>
      <Box sx={{bgcolor: 'background.default',color: 'text.primary',}} className='App'> 
      <Routes>
        <Route path="/" element={<Home auth={authenticated}  />} />
        <Route path="/post/:url" element={<Post auth={authenticated} />} />
        <Route path="/contactos" element={<Contact />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="*" element={<Error/>}/>
        <Route path="/search" element={<Search />} />
        <Route path="/page/privacy-policy" element={<PrivacyPolicy auth={authenticated} type="new" userId={userId}/>} />

      </Routes>
      </Box>
</>
}
</ThemeProvider>

  );
};


function App() {
  return (
    <Router>
      <Root />
    </Router>
  );
}

export default App;
