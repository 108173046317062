import React, { useEffect, useState } from 'react';
import Foot from '../../Theme/Foot';
import ItemMessage from '../../Components/ItemMessage';
import { Container, Grid, CircularProgress, Button, Box, Paper } from '@mui/material';
import handleApiRequest from '../../ApiUtils';
import Spinner from '../../Components/Spinner';
import DontFound from '../../Components/DontFound';

import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';

const Panel = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const [profile, setProfile] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const pageName = 'Mensajes';
  useEffect(() => { document.title = pageName; }, []);







  const getData = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', 'user_messages&user_id=' + userId);
      if (response.status === 'success') {
        setMessages(response.data);
        setIsLoading(false);
      } else {
        console.error('Error al obtener la lista de proyectos:');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);


  const groupedMessages = messages.reduce((groups, message) => {
    const key = message.title;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(message);
    return groups;
  }, {});





  if (isLoading) return <Spinner loading={true} />;

  return (<>

    <Box className='container-page' mb={5}>
      <h3>Mensajes</h3>
      <hr/>

      {groupedMessages.length ? 

      <Box sx={{ mt: 3 }}>
        {Object.entries(groupedMessages).map(([title, messagesForTitle]) => (
          <>
            <Paper key={title} sx={{ px: 2, pb: .2, pt: .1, mb: 2, backgroundColor: '#f4f4f4' }}>
              <h4>{title}</h4>
            </Paper>
            {messagesForTitle.map(post => (
              <ItemMessage
                key={post.post_id}
                message={post.message}
                date={post.create_at}
                userName={post.user_name}
                link={'/post/' + post.post_id}
                email={post.email}
                phone={post.phone}
              />
            ))}

          </>
        ))}
      </Box>
      :
      <DontFound icon="cancel" message="No se encontraron mensajes"/>

}
    </Box>
    <Foot />
  </>
  );
};

export default Panel;
