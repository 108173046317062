import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

import Spinner from '../Components/Spinner';
import handleApiRequest from '../ApiUtils';

const RegisterPage = () => {
  const [err, setErr] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [isChecked, setChecked] = useState(false);


  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    setErr('')
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isChecked) {
      setIsLoading(true);
      if (password.length > 5) {
        try {
          const response = await handleApiRequest('post', 'register', {
            register: true,
            email: email,
            name: name,
            password: password
          });
          if (response.status == 'success') {
            localStorage.setItem('token', response.data.user_id);
            localStorage.setItem('u_name', response.data.user_name);
            setTimeout(() => {
              window.location.reload();
            }, 500); // espera 500 milisegundos (ajusta según sea necesario)
          } else {
            setIsLoading(false);
            setErr(response.message);
          }
        } catch (error) {
          setIsLoading(false);
          alert(error); // Agrega esta línea para imprimir detalles del error en la consola del navegador
          setErr('Error. No se puede conectar con el servidor.');

        }
      } else {
        setIsLoading(false);
        setErr('La contraseña no puede tener menos de 6 caracteres');
      }
    } else {
      setIsLoading(false);
      setErr('Es necesario aceptar los terminos y condiciones')
    }
  };




  if (isLoading) return <Spinner loading={true} />;

  return (
    <div>


      <form onSubmit={handleSubmit}>

        <TextField
          label="Nombre"
          fullWidth
          margin="normal"
          required
          onChange={(e) => { setErr(''); setName(e.target.value) }}
        />


        <TextField
          label="Correo electronico"
          fullWidth
          type='email'
          margin="normal"
          required
          onChange={(e) => { setErr(''); setEmail(e.target.value) }}
        />

        <TextField
          label="Contraseña"
          fullWidth
          type='password'
          margin="normal"
          required
          onChange={(e) => { setErr(''); setPassword(e.target.value) }}
        />

        {err && (
          <Box sx={{ padding: 1, bgcolor: '#FF94A1', color: 'white', borderRadius: 0 }}>
            {err}
          </Box>
        )}

        <FormGroup sx={{ mt: 2 }}>

          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                name="termsCheckbox"
              />
            }
            label={
              <Typography variant="body2">
                Acepto los{' '}
                <a href="/terminos" target="_blank" rel="noopener noreferrer">
                  términos y condiciones
                </a>{' '}
                y la{' '}
                <a href="/privacidad" target="_blank" rel="noopener noreferrer">
                  política de privacidad
                </a>
              </Typography>
            }
          />
        </FormGroup>


        <Button
          sx={{ marginTop: 5 }}
          size='large' variant="contained" color="error" fullWidth type="submit">
          Registrarme
        </Button>
      </form>
    </div>
  );
};

export default RegisterPage;
