import React, { useEffect, useState } from 'react';
import Foot from '../Theme/Foot';
import Spinner from '../Components/Spinner';
import Item from '../Components/Item';
import TopSearch from '../Components/TopSearch';
import handleApiRequest from '../ApiUtils';
import im1 from '../Assets/Images/top-3.png';

import { List, Paper, Grid, CircularProgress, Button, Box } from '@mui/material';


const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    document.title = 'Brilancer.';
  }, []);





  useEffect(() => {
    getFeatured();
    getData();
  }, []);

  const getFeatured = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', 'list_posts&featured=1');
      if (response.status === 'success') {
        setFeatured(response.data);
        setIsLoading(false);
      } else {
        console.error('Error al obtener la lista de proyectos:');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };



  const getData = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', 'list_posts');
      if (response.status === 'success') {
        setPosts(response.data);
        setIsLoading(false);
      } else {
        console.error('Error al obtener la lista de proyectos:');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };

  if (isLoading) return <Spinner loading={true} />;
  return (<>
    <Box sx={{ position: 'relative', mt: -3, width: '100%',  backgroundColor: '#0C4824' }}>

      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
        <img src={im1} alt="Logo" className='logo' style={{ height: '500px', width: '100%', height: '500px', objectFit: 'cover' }} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '30%',
          left: '25%',
          transform: 'translateX(-50%)',
          minHeight: '240px',
          py: 2,
          px: 4,
          color: '#fff',
          maxWidth: '55%',
          boxSizing: 'border-box',
          display: { xs: 'none', sm: 'block', md: 'block' }
         }}
      >
        <Box sx={{ maxWidth: '1350px', marginLeft: 'auto', marginRight: 'auto' }}>
          <TopSearch Button='false' />
        </Box>
      </Box>

      <Box sx={{px:2, py:3,display: { xs: 'block', sm: 'none', md: 'none'}  }}>
          <TopSearch ButtonType='bottom' />
        </Box>

    </Box>

    <Box className='container-page' sx={{ mb: 8 }}>


      <div >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {featured.map((post) => (
                <Grid item key={post.post_id} xs={12} sm={6} md={4} lg={3}>
                  <Item
                    title={post.title}
                    description={post.description}
                    category={post.category}
                    date={post.create_at}
                    id={post.post_id}
                    url="post/"
                    horizontalOrVertical="vertical"
                    featured={1}
                    image={post.image}

                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </div>


      <div >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ mt: 3 }}>
            {posts.map((post) => (
              <Item
                title={post.title}
                description={post.description}
                category={post.category}
                date={post.create_at}
                id={post.post_id}
                url="post/"
                horizontalOrVertical="horizontal"

              />
            ))}
          </Box>
        )}
      </div>

      <Box sx={{ textAlign: 'center' }}>
        <Button type="submit" variant="contained" sx={{ mt: 3 }}>
          VER TODAS LAS PROPUESTAS
        </Button>
      </Box>


    </Box>


    <Box >


      <Foot />

    </Box>
  </>
  );
};

export default Home;
