import React, { useState } from 'react';
import { TextField, InputBase, MenuItem, Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Importa el ícono de búsqueda
import { Link,useLocation, useNavigate } from 'react-router-dom';

  const Buscador = ({search,ButtonType, text}) => {
    const navigate = useNavigate();

  const [q, setQ] = useState('');
  const [val, setVal] = useState(search?search : '');



  const handleSearch = () => {
    navigate('/search/?q='+q);
    window.location.reload(); // Recargar la página actual
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


  return (
    <Box sx={{color:'#fff'}}>
    {text === false ?null :  <>
      <h1>Conectá con las oportunidades</h1>
    <p>Busca socios, inversores y más...</p>
    </>
    }
  
  
    <Box
    sx={{
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 1,
      backgroundColor: '#fff' ,
      border: '0px solid #fff', // Borde
      borderRadius: 0, // Sin border radius
    }}
  >

    <InputBase
      placeholder={val ? val :"Busca socios, inversores o trabajo"}
      variant="outlined"
      onChange={(e) => setQ(e.target.value)}
      onKeyDown={handleKeyPress}
      size="large"
      sx={{
        flex: 1,
        marginRight: 1,
        p:2,
        borderRadius: 0,
        height:'50px'
      }}
    />
    {ButtonType == 'none' || ButtonType == 'bottom'  ? null :
    <Button
      size='large'
      variant="contained"
      color="error"
      onClick={handleSearch}
      sx={{ width: 50, height: 50, padding: 0, minWidth: 0, borderRadius:0 }}
    >
      <SearchIcon />
    </Button>
  }

  </Box>
  {ButtonType == 'bottom' ? 

  <Button
      size='large'
      variant="contained"
      color="error"
      fullWidth
      onClick={handleSearch}
      sx={{ width: '100%', height: 50,  minWidth: 0, borderRadius:0 }}
    >
      <SearchIcon />
    </Button>

  :null}

  </Box>

  );
};

export default Buscador;
