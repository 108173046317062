import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '70vh',
  },
  emoji: {
    fontSize: '5rem',
  },
  title: {
    margin: '16px',
  },
  button: {
    marginTop: '16px',
  },
};

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <Typography variant="h1" component="h1" style={styles.emoji}>
        😕
      </Typography>
      <Typography variant="h4" align='center' component="h4" style={styles.title}>
        Oops! Página no encontrada
      </Typography>
      <Typography variant="body1" component="p">
        Parece que has llegado a una página que no existe.
      </Typography>
      <Button
        component={Link}
        to="/"
        style={styles.button}
      >
        Volver al inicio
      </Button>
    </div>
  );
};

export default NotFoundPage;
