import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../Components/Breadcrumb';

import axios from "axios";
import Foot from '../Theme/Foot';
import Spinner from '../Components/Spinner';
import Item from '../Components/Item';
import TopSearch from '../Components/TopSearch';
import handleApiRequest from '../ApiUtils';
import DontFound from '../Components/DontFound';

import { Typography, Grid, CircularProgress, Paper, Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
const Home = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get('q');
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState(q);
  const [posts, setPosts] = useState([]);


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', 'search=' + search);
      if (response.status === 'success') {
        setPosts(response.data);
        setIsLoading(false);
      } else {
        console.error('Error al obtener la lista de posts');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };




  if (isLoading) return <Spinner loading={true} />;
  return (<>


    <Box sx={{ background: '#ccc', color: '#fff'}} >
      <Box sx={{py: 2,maxWidth:'1350px', ml:'auto', mr:'auto', pt:3, px:2,mt:-1}}>
        <TopSearch search={search} text={false} />
      </Box>
    </Box>
    <Box className='container-page' sx={{ mb: 8 }}>

      <Box >
        {isLoading ? (
          <CircularProgress />
        ) : (
          posts.length ?
            <Box sx={{ mt: 3 }}>
              {posts.map(post => (
                <Item
                  title={post.title}
                  description={post.description}
                  category={post.category}
                  date={post.create_at}
                  id={post.post_id}
                  horizontalOrVertical="horizontal"

                />
              ))}
            </Box>

            :


            <DontFound icon="search" message="No se encontraron resultados para la búsqueda." />


        )}
      </Box>

    </Box>
    <Foot />
  </>
  );
};

export default Home;
