import React, { useEffect, useState } from 'react';
import Foot from '../../Theme/Foot';
import Breadcrumb from '../../Components/Breadcrumb';
import { Container, Grid, Typography, TextField, Button, Box, Paper } from '@mui/material';
import handleApiRequest from '../../ApiUtils';
import Spinner from '../../Components/Spinner';

const Panel = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const pageName = 'Panel';
  useEffect(() => { document.title = pageName; }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const response = await handleApiRequest('post', `update_profile`, {
        update_profile: true,
        user_id: userId,
        name: name,
        email: email,
        phone: phone,
        password: password
      });
      if (response.status === 'success') {
        setIsLoading(false)

        alert('Datos actualizados exitosamente.');
        getData();
      } else {
        setIsLoading(false)
        alert('Hubo un error al actualizar los datos.');
      }
    } catch (error) {
      setIsLoading(false)
      alert('Hubo un error en la solicitud.');
    }

  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    if (oldPassword === password) {
      try {
        const response = await handleApiRequest('post', `update_profile`, {
          update_profile: true,
          user_id: userId,
          password: newPassword
        });
        if (response.status === 'success') {
          setIsLoading(false)
          alert('Contraseña actualizada exitosamente.');
          getData();
          setOldPassword('')
          setNewPassword('')
        } else {
          setIsLoading(false)
          alert('Hubo un error al actualizar los datos.');
        }
      } catch (error) {
        setIsLoading(false)
        alert('Hubo un error en la solicitud.');
      }
    } else {
      setIsLoading(false)
      alert('Las contraseñas no coinciden.');

    }
  };





  const getData = async () => {
    setIsLoading(true)

    try {
      const response = await handleApiRequest('get', `user_profile&user_id=${userId}`);
      if (response.status === 'success') {
        setIsLoading(false)
        setProfile(response.data.profile);
        setName(response.data.profile.name)
        setEmail(response.data.profile.email)
        setPhone(response.data.profile.phone)
        setPassword(response.data.profile.password)
      } else {
        setIsLoading(false)
        console.error('Error al obtener el perfil.');
      }
    } catch (error) {
      setIsLoading(false)
      alert('Hubo un error en la solicitud.');
    }
  };

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);


  const handleValidatePhoneNumber = (phoneNumber) => {
    if (phoneNumber) {

      // Eliminar espacios en blanco y caracteres no numéricos
      const cleanedNumber = phoneNumber.replace(/\D/g, '');

      // Verificar si el número ya tiene el prefijo internacional "+54"
      if (cleanedNumber.startsWith('+54')) {
        // Verificar si el número tiene 13 caracteres (incluyendo el "+54")
        if (cleanedNumber.length === 13) {
          return cleanedNumber; // Devolver el número sin cambios
        }
      }

      // Validar longitud del número sin el prefijo "+54"
      if (cleanedNumber.length === 10) {
        // Agregar el prefijo internacional "+54" al número
        const formattedNumber = `+54${cleanedNumber}`;
        return formattedNumber;
      }

      // Devolver el número sin cambios si no cumple con las condiciones
      return phoneNumber;
    }
  };


  if (isLoading) return <Spinner loading={true} />;

  return (<>

    <Box className='container-page' mb={5}>
      <h3>Configuracion</h3>
      <hr/>
      <Paper sx={{ p: 2, mt: 3, pt: 1 }}>
        <form onSubmit={handleSubmit}>

          <h3>Datos Personales</h3>
          <TextField sx={{ mt: 2 }}
            label="Nombre Completo"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField sx={{ mt: 2 }}
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />

          <TextField sx={{ mt: 2 }}
            label="WhatsApp"
            type="phone"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
          />





          <Button type="submit" variant="contained" sx={{ mt: 3 }} className='button-width'>
            Actualizar
          </Button>
        </form>
      </Paper>





      <Paper sx={{ p: 2, pt: 1, mt: 3 }}>
        <form onSubmit={handlePasswordSubmit}>

          <h3>Cambiar Contraseña</h3>
          <TextField sx={{ mt: 2 }}
            label="Contraseña actual"
            type="password"
            required
            onChange={(e) => setOldPassword(e.target.value)}
            fullWidth
          />

          <TextField sx={{ mt: 2 }}
            label="Nueva Contraseña"
            type="password"
            required
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
          />

          <Button type="submit" variant="contained" sx={{ mt: 3 }} className='button-width'>
            Actualizar
          </Button>
        </form>
      </Paper>

    </Box>
    <Foot />
  </>
  );
};

export default Panel;
