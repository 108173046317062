import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Importa useParams desde react-router-dom
import Foot from '../Theme/Foot';
import Spinner from '../Components/Spinner';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import handleApiRequest from '../ApiUtils';
import { extractIdFromUrl } from '../Functions';


import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';

const PublicarBusqueda = ({ userId, type }) => {
  const { url } = useParams(); // Usa useParams para obtener el parámetro "id"
  const [id, setId] = useState(url ? extractIdFromUrl(url) : null)

  const [isLoading, setIsLoading] = useState(false);
  const [criteria, setCriteria] = useState('');
  const [proposals, setProposals] = useState('');
  const [categories, setCategories] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [rubrosSeleccionados, setRubrosSeleccionados] = useState([]);
  const [descripcion, setDescripcion] = useState(''); // Nuevo campo de descripción

  const handleRubroClick = (rubro) => {
    if (rubrosSeleccionados.includes(rubro)) {
      setRubrosSeleccionados((prevSelected) =>
        prevSelected.filter((selected) => selected !== rubro)
      );
    } else {
      setRubrosSeleccionados((prevSelected) => [...prevSelected, rubro]);
    }
  };


  useEffect(() => {
    fetchCategories();
    if(type == "edit"){
      getEdit();
    }
    
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', 'list_categories');
      if (response.status === 'success') {
        setCategories(response.data);
        setIsLoading(false);
      } else {
        console.error('Error al obtener la lista de categorias:');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }

  };


  const getEdit = async () => {
    setIsLoading(true);

    try {
      const response = await handleApiRequest('get', `details&id=${id}`);
      if (response.status === 'success') {
        document.title = response.data.post.title;
        setIsLoading(false);
        setCriteria(response.data.post.partner_criteria)
        setProposals(response.data.post.partner_proposals)
        setTitulo(response.data.post.title)
        setDescripcion(response.data.post.description)
      } else {
        console.error('Error al obtener la lista de proyectos.');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };



  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      const response = await handleApiRequest('post', 'create_or_update_post', {
        create_or_update_post: true,
        post_id:type == "edit" ? id : '',
        user_id: userId,
        title: titulo,
        category: 1,
        description: descripcion,
        criteria: criteria,
        proposals: proposals

      });
      if (response.status === 'success') {
        setIsLoading(false);
        alert('Proyecto creado exitosamente.');
      } else {
        setIsLoading(false);
        alert('Hubo un error al crear el proyecto.');
      }
    } catch (error) {
      setIsLoading(false);
      alert('Hubo un error en la solicitud.');
    }
  };


  if(isLoading) return <Spinner loading={true} />;
  return (<>
    <Box className='container-page' sx={{ mb: 10 }}>
      {type == "new" ? <Box>
        <h2>Comparte tu Idea</h2>
        <p>
          ¡Cuéntanos acerca de tu proyecto! Completa este formulario con los detalles relevantes y te ayudaremos a encontrar el socio ideal.
        </p>
      </Box>
        :
        <Box>
        <h1>Editar publicacion</h1>
  
      </Box>

    }

      <form onSubmit={handleSubmit}>
        <TextField sx={{ mt: 2 }}
          label="Título de tu idea de negocio"
          required
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          fullWidth
        />

        <TextField sx={{ mt: 2 }}
          label="Descripción del Proyecto"
          required
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          fullWidth
          multiline
          rows={4} // Puedes ajustar el número de filas según tus necesidades
        />

        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ mb: 1 }}>Categoriass *</Box>
          {categories.map((category) => (
            <Chip
              key={category.id}
              label={category.category}
              clickable
              color={rubrosSeleccionados.includes(category) ? 'primary' : 'default'}
              onClick={() => handleRubroClick(category)}
              sx={{ margin: 1 }}
            />
          ))}
        </Box>

        <TextField sx={{ mt: 2 }}
          label="¿Qué buscás para llevar adelante tu idea?"
          required
          value={criteria}
          onChange={(e) => setCriteria(e.target.value)}
          fullWidth
          multiline
        />
        <TextField sx={{ mt: 2 }}
          label="¿Qué estarías dispuesto a proponerle a la persona que te ayude en este proyecto?"
          required
          value={proposals}
          onChange={(e) => setProposals(e.target.value)}
          fullWidth
          multiline
        />

        <Button type="submit" className='button-width' size='large' variant="contained" color="primary" sx={{ mt: 5 }}>
          {type == "edit" ? 'Actualizar': 'Publicar'}
        </Button>
      </form>
    </Box>
    <Foot />
  </>
  );
};

export default PublicarBusqueda;
