import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const Spinner = forwardRef((props, ref) => {
  const [open, setOpen] = useState(props.loading);

  useImperativeHandle(ref, () => ({
    showSpinner() {
      setOpen(true);
    },
    hideSpinner() {
      setOpen(false);
    }
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ backgroundColor:'#fff',color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
       // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
});

export default Spinner;
