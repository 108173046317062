import React from 'react';
import Box from '@mui/material/Box';
import ComputerIcon from '@mui/icons-material/Computer';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FashionIcon from '@mui/icons-material/Home';
import HealthIcon from '@mui/icons-material/HealthAndSafety';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ForestIcon from '@mui/icons-material/Forest';
import TimeAgo from './TimeAgo';
import { createFriendlyURL, truncatedText } from '../Functions';
import im1 from '../Assets/Images/prueba.png';
import VerifiedIcon from '@mui/icons-material/Verified';

const Item = ({ title, description, date, id, category, url, horizontalOrVertical, featured, image }) => {
  const isVertical = horizontalOrVertical === 'horizontal';

  // Define un objeto que asocie cada categoría con su ícono
  const categoryIcons = {
    'Alimentos': <RestaurantIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }} />,
    'Moda e Indumentaria': <FashionIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }}  />,
    'Tecnología': <ComputerIcon style={{ ...styles.icon,  fontSize: !isVertical ? '40px' : '35px' }} />,
    'Salud y Bienestar': <HealthIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }}  />,
    'Turismo y Hospitalidad':<RoomServiceIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }}  />,
    'Bienes y Raíces':<MapsHomeWorkIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }}  />,
    'Arte y Entretenimiento':<ColorLensIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }} />,
    'Energía y Medio Ambiente':<ForestIcon style={{ ...styles.icon,  fontSize: !isVertical ? '100px' : '35px' }}  />

    
    // Agrega más categorías e iconos según sea necesario
  };


  const containerStyle = isVertical ? { flexDirection: 'row', alignItems: 'center'} : {minHeight:'380px',flexDirection: 'column' };
  const imageStyle = isVertical ? { height: '80px', width: '80px', objectFit: 'cover', marginRight:'15px'} : {height: '180px', width: '100%', objectFit: 'cover', marginBottom:'10px' };

  return (
    <a href={url + `${createFriendlyURL(title) + '-' + id}`} style={styles.link}>
      <div style={{ ...styles.container, ...containerStyle }}>
      {image ? 
        <img src={image} alt={title} style={imageStyle} />
        :null
      }
        <div style={styles.content}>
          <span style={styles.category}>{category}</span>
          <h2 style={styles.title}>{truncatedText(title, 60)}</h2>
          <p style={styles.description}>{truncatedText(description, 120)}</p>
          <p style={styles.date}><TimeAgo dateString={date} /></p>
          { featured ==1 ? 
          <Box sx={{position:'absolute', top:'10px', left:'10px', 
          color:'#fff', fontWeight:'800', 
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          fontSize:'14px', backgroundColor:'#002C8B',px:'10px', py:'3px', borderRadius:'4px'}}>
            <small>D E S T A C A D O</small>
          </Box>
            :null
          }
        </div>
      </div>
    </a>
  );
};

const styles = {
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  container: {
    display: 'flex',
    border: '1px solid #E4E5E7',
    padding: '10px',
    borderRadius: '0px',
    marginBottom: '10px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  icon: {
    fontSize: '35px',
    color: '#1976D2',
    magin:0

  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '0px',
  },
  description: {
    fontSize: '14px',
    margin: '10px 0',
    marginTop: '2px',
  },
  button: {
    backgroundColor: '#fff',
    color: 'black',
    fontWeight: '200',
  },
  date: {
    fontSize: '14px',
    color: '#888',
    position: 'absolute',
    bottom: '-5px',
    right: '20px',
  },
  category: {
    fontSize: '14px',
    color: '#888',
    maginTop:'10px',
    

  },
};

export default Item;
