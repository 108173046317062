import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

const style = {
  modalContainer: {zIndex:9999999},
  modalContent: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: '40%',
    left: '50%',
    height:'90vh',
    transform: 'translate(-50%, -40%)',
    maxWidth: 550,
    width: '100%', // Modificación: El modal ocupa el 100% de ancho
    boxShadow: 24,
    borderRadius: '15px',
    padding: '5px', // Agrega el padding deseado aquí
    zIndex:9999999
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
  },
  title: {
    flexGrow: 1,
    fontSize: '2.3vh',
    fontWeight: 'bold',
    textAlign: 'center',
    maginBottom: 0,
  },
  closeButton: {},
};

const CustomModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);


  const [modalContent, setModalContent] = useState(null);
  
  const openModal = (content) => {
    setModalContent(content);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
  }));

  return (
    <React.Fragment>
      <Modal open={open} onClose={closeModal} style={style.modalContainer}>
        <Box
          sx={{
            ...style.modalContent,
            '@media (max-width: 600px)': {
              width: '100%', // Modificación: El modal ocupa el 100% de ancho en tamaño "sm"
              left: '0',
              top:0,
              bottom:'0',
              transform: 'translate(0, 0)',
              margin: '0',
              height:'100vh',
              borderRadius: '0',
            },
          }}
        >
          <div style={style.header}>
            <Typography variant="h6" style={style.title}>
              {props.modalTitle}
            </Typography>
            <IconButton
              style={style.closeButton}
              color="inherit"
              onClick={closeModal}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
          <div style={{ 
            padding: '20px', 
            overflow: 'auto', 
            maxHeight:'75vh',

            }}>
            {modalContent}
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
});

export default CustomModal;
